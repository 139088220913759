<template>
  <div>
    <v-toolbar tile flat :elevation="$vuetify.breakpoint.xsOnly ? '0' : '1'">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ contestTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <!-- <v-container v-if="!loadPage"> -->
    <v-container>
      <contest-big-card></contest-big-card>
      <contest-details></contest-details>
      <v-card v-if="contest" class="mt-4">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Правила</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-col cols="12" v-html="contest.rules"></v-col>
      </v-card>
      <comments
        v-if="contest"
        :commentable_id="contest.id"
        commentable_type="contest"
        :commentable_slug="contest.slug"
        :entity_user_id="1"
        :route_name="$route.name"
      ></comments>
      <!-- <v-card v-if="contest && +contest.can_comment && showRulesButton" class="block-comments mt-4">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>комментарии</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showCommentAdd = !showCommentAdd">
            <v-icon>$vuetify.icons.plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-slide-y-transition>
          <div v-if="showCommentAdd" class="comment-add">
            <v-form v-if="currentUser" v-model="valid" ref="form">
              <v-textarea
                name="inputComment"
                label="Текст комментария"
                value
                v-model="commentText"
                :rules="commentTextRules"
              ></v-textarea>
              <v-btn dark color="primary" @click="submitComment">Добавить</v-btn>
            </v-form>
            <div class="text-center" v-else>
              Вы должны
              <v-btn :to="{name: 'Login'}" tile link text>авторизоваться</v-btn>
            </div>
          </div>
        </v-slide-y-transition>
        <div v-if="comments && comments.length > 0" class="comments">
          <comment-list :comments="comments" entity_user_id="1" commentable_type="contest"></comment-list>
          <v-pagination
            v-if="comments_pages > 1"
            @input="goPage"
            v-model="comments_page"
            :length="comments_pages"
            :total-visible="7"
            class="mt-2"
          ></v-pagination>
        </div>
        <div v-else class="comments">
          <no-content title="Комментариев нет"></no-content>
        </div>
      </v-card> -->

      <!-- <v-dialog v-model="dialogDeleteComment" class="text-center" max-width="290">
        <v-card>
          <v-card-title class="headline justify-center">Вы уверены?</v-card-title>

          <v-card-text>Что хотите удалить данную запись.</v-card-text>

          <v-card-actions class="justify-space-around">
            <v-btn color="red darken-1" text @click="deleteComment">Да</v-btn>

            <v-btn color="green darken-1" text @click="dialogDeleteComment = false">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ContestBigCard from "./ContestBigCard.vue";
import ContestDetails from "./ContestDetails.vue";
import Comments from "../comment/Comments.vue";
export default {
  components: {
    ContestBigCard,
    ContestDetails,
    Comments,
  },
  data: () => ({
    slug: "",
    sort: "new",
    page: 1,
    loading: false,
    // valid: true,
    // dialogDeleteComment: false,
    // deleteCommentId: null,
    // showCommentAdd: false,
    // commentText: "", //Текст комментария
    // commentTextRules: [
    //   (v) => !!v || "Поле обязательно",
    //   (v) => !!v === v.length >= 1 || "Минимум 1 символ",
    // ],
    // loadingComments: false,
  }),
  methods: {
    // validate() {
    //   return this.$refs.form.validate();
    // },
    getContest() {
      var app = this;

      this.$store.dispatch("contest/getContest", {
        slug: app.slug,
        page: app.page,
        sort: app.sort,
      });
    },
    // deleteCommentModal(commentId) {
    //   this.deleteCommentId = commentId;
    //   this.dialogDeleteComment = true;
    // },
    // goPage(nextPage) {
    //   //Изменить на подзагрузку комментов
    //   if (nextPage != this.$route.query.page) {
    //     this.$router.push({
    //       name: this.$route.name,
    //       params: { slug: this.slug },
    //       query: { page: nextPage },
    //     });
    //   }
    // },
    // submitComment() {
    //   event.preventDefault();
    //   if (!this.validate()) return;
    //   let app = this;

    //   this.$store
    //     .dispatch("comments/addComment", {
    //       body: app.commentText,
    //       commentable_id: app.contest.id,
    //       parent_id: null,
    //       commentable_type: "contest",
    //     })
    //     .then(() => {
    //       app.clearComment();
    //     })
    //     .catch(() => {});
    // },

    // clearComment() {
    //   this.commentText = "";
    //   this.showCommentAdd = false;
    // },
    // deleteComment() {
    //   this.loadingComments = true;
    //   let app = this;
    //   this.$store
    //     .dispatch("comments/dellComment", {
    //       comment_id: app.deleteCommentId,
    //       commentable_type: "contest",
    //     })
    //     .then(() => {
    //       app.dialogDeleteComment = false;
    //     })
    //     .catch(() => {
    //       app.dialogDeleteComment = false;
    //     });
    // },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      contest: "contest/contest",
      // comments: "comments/comments",
      // comments_pages: "comments/comments_pages",
    }),
    // comments_page: {
    //   get() {
    //     return this.$store.getters["comments/comments_page"];
    //   },
    //   set(val) {
    //     this.$store.commit("comments/SET_PAGE", val);
    //   },
    // },
    contestTitle() {
      return "Правила";
    },
    showRulesButton() {
      //Показываем ссылку на правила
      if (this.contest && this.contest.status < 3) {
        return true;
      }
      return false;
    },
  },
  created() {
    // this.$eventBus.$on("delete-comment", this.deleteCommentModal);
    this.slug = this.$route.params.slug ? this.$route.params.slug : null;
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.sort = this.$route.query.sort ? this.$route.query.sort : "new";
    this.getContest();
  },
  beforeDestroy() {
    // if (this.$route.name != "ContestRules") {
    //   //Костыль
    //   this.$eventBus.$off("delete-comment");
    // }
  },
};
</script>
